var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contract-list-dt"},[[_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.dColumns,"items":_vm.dItems,"disable-infinite-scroll":!_vm.dHasNext,"loading":_vm.cLoading,"mobile-view":_vm.cMobileView,"infinite-scroll":"","fixed-header":""},on:{"fetch-more":_vm.fetchMore},scopedSlots:_vm._u([{key:"column-addressname",fn:function(ref){
var value = ref.value;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-4 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col-8"},[_c('router-link',{attrs:{"to":{
                                name: 'address-detail',
                                params: { id: value.address }
                            },"title":value.address}},[_c('f-ellipsis',{attrs:{"text":value.address,"overflow":"middle"}})],1),_c('span',{staticClass:"contract-name"},[_vm._v(_vm._s(value.name))])],1)]):[_c('router-link',{attrs:{"to":{
                            name: 'address-detail',
                            params: { id: value.address }
                        },"title":value.address}},[_c('f-ellipsis',{attrs:{"text":value.address,"overflow":"middle"}})],1),_c('span',{staticClass:"contract-name"},[_vm._v(_vm._s(value.name))])]]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }