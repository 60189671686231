var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block-list-dt"},[(!_vm.dBlockListError)?[_c('f-data-table',_vm._b({staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.dColumns,"items":_vm.dItems,"disable-infinite-scroll":!_vm.dHasNext,"loading":_vm.cLoading,"infinite-scroll":"","fixed-header":""},on:{"fetch-more":_vm.fetchMore},scopedSlots:_vm._u([{key:"column-block",fn:function(ref){
var value = ref.value;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-4 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":{
                                name: 'block-detail',
                                params: { id: value }
                            },"title":value}},[_vm._v(_vm._s(value))])],1)]):[_c('router-link',{attrs:{"to":{
                            name: 'block-detail',
                            params: { id: value }
                        },"title":value}},[_vm._v(_vm._s(value))])]]}},{key:"column-age",fn:function(ref){
                        var value = ref.value;
                        var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-4 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[_c('timeago',{attrs:{"datetime":_vm.timestampToDate(value),"auto-update":1,"converter-options":{ includeSeconds: true }}})],1)]):[_c('timeago',{attrs:{"datetime":_vm.timestampToDate(value),"auto-update":5,"converter-options":{ includeSeconds: true }}})]]}}],null,false,1754182663)},'f-data-table',Object.assign({}, _vm.$attrs, _vm.$props),false))]:[_c('div',{staticClass:"query-error"},[_vm._v(_vm._s(_vm.dBlockListError))])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }