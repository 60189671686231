<template>
    <div class="">
        <div
            v-click-outside="hide"
            ref="selector"
            class="f-select"
            tabindex="0"
        >
            <button
                class="current-label"
                ref="dropdown"
                @click="active = !active"
            >
                <span v-if="!val" class="default-label">
                    Type here
                </span>
                <span v-else class="label-text ">
                    {{ val }}
                </span>
                <img src="/img/arrow-down.svg" class="img" />
            </button>

            <div v-if="active" class="options">
                <div
                    v-for="(option, idx) of labels"
                    :key="`${option.name}-${idx}`"
                    class="option"
                    tabindex="0"
                    @click="onSelect(option)"
                    @keyup.enter="onSelect(option)"
                >
                    <span :class="{ 'text-active': val === option.name }">{{
                        option.name
                    }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
export default {
    name: "FSelect",
    props: {
        value: String
    },
    data() {
        return {
            val: this.value,
            active: false,
            labels: [
                {
                    name: "Send a transfer without comment",
                    minStakingAmount: 134, // if minStakingAmount less then 134, countTxPerEpoch = 0
                    countTxPerEpoch: 6971
                },
                {
                    name: "Stake for payback",
                    minStakingAmount: 134,
                    countTxPerEpoch: 941
                },
                {
                    name: "Cancel staking",
                    minStakingAmount: 134,
                    countTxPerEpoch: 941
                },
                {
                    name: "Stake for payback with callback",
                    minStakingAmount: 134,
                    countTxPerEpoch: 844
                },
                {
                    name: "Cancel staking with callback",
                    minStakingAmount: 134,
                    countTxPerEpoch: 844
                },
                {
                    name: "Transfer token ownership",
                    minStakingAmount: 134,
                    countTxPerEpoch: 714
                },
                {
                    name: "Change token type",
                    minStakingAmount: 134,
                    countTxPerEpoch: 844
                },
                {
                    name: "Query token information",
                    minStakingAmount: 134,
                    countTxPerEpoch: 3098
                }
            ]
        };
    },
    watch: {
        value(_val) {
            this.val = _val;
        }
    },
    methods: {
        onSelect(value) {
            console.log(value);
            this.$emit("select", value);
            this.active = false;
        },
        hide() {
            this.active = false;
        }
    },
    directives: {
        ClickOutside
    }
};
</script>

<style lang="scss" scoped>
@import "style";
</style>
