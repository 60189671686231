var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"address-asset-list-dt"},[_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.columns,"items":_vm.cTokens,"first-m-v-column-width":"6"},scopedSlots:_vm._u([{key:"column-asset",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-crypto-symbol',{attrs:{"token":item}})],1)]):[_c('f-crypto-symbol',{attrs:{"token":item}})]]}},{key:"column-name",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":{
                            name: 'asset-detail',
                            params: { address: item.address }
                        },"title":value}},[_vm._v(" "+_vm._s(value)+" ")])],1)]):[_c('router-link',{attrs:{"to":{
                        name: 'asset-detail',
                        params: { address: item.address }
                    },"title":value}},[_vm._v(" "+_vm._s(value)+" ")])]]}},{key:"column-address",fn:function(ref){
                    var value = ref.value;
                    var item = ref.item;
                    var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":{
                            name: 'asset-detail',
                            params: { address: item.address }
                        },"title":value}},[_vm._v(" "+_vm._s(_vm._f("formatHash")(item.address))+" ")])],1)]):[_c('router-link',{attrs:{"to":{
                        name: 'asset-detail',
                        params: { address: item.address }
                    },"title":value}},[_vm._v(" "+_vm._s(_vm._f("formatHash")(item.address))+" ")])]]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }