<template>
    <ul class="f-social-media-links no-markers">
        <li class="icon">
            <a
                href="https://discord.gg/vinu"
                target="_blank"
                rel="nofollow"
                title="VinuChain on Discord"
            >
                <icon
                    data="@/assets/svg/social-media/discord.svg"
                    width="24"
                    height="24"
                ></icon>
            </a>
        </li>
        <li class="icon">
            <a
                href="https://twitter.com/vinuchain"
                target="_blank"
                rel="nofollow"
                title="VinuChain on Twitter"
            >
                <icon
                    data="@/assets/svg/social-media/twitter.svg"
                    width="24"
                    height="24"
                ></icon>
            </a>
        </li>
        <li class="icon">
            <a
                href="https://reddit.com/r/vitainu"
                target="_blank"
                rel="nofollow"
                title="VinuChain on Reddit"
            >
                <icon
                    data="@/assets/svg/social-media/reddit.svg"
                    width="24"
                    height="24"
                ></icon>
            </a>
        </li>
        <li class="icon">
            <a
                href="https://github.com/vita-inu"
                target="_blank"
                rel="nofollow"
                title="VinuChain on Github"
            >
                <icon
                    data="@/assets/svg/social-media/github.svg"
                    width="24"
                    height="24"
                ></icon>
            </a>
        </li>
        <li class="icon">
            <a
                href="https://medium.com/vinu-foundation"
                target="_blank"
                rel="nofollow"
                title="VinuChain on Medium"
            >
                <icon
                    data="@/assets/svg/social-media/medium.svg"
                    width="24"
                    height="24"
                ></icon>
            </a>
        </li>
        <li class="icon">
            <a
                href="https://www.linkedin.com/company/vinufoundation/"
                target="_blank"
                rel="nofollow"
                title="VinuChain on Linkedin"
                class="linkedin"
            >
                <!-- <icon
                    data="@/assets/svg/social-media/linkedin.svg"
                    width="24"
                    height="24"
                ></icon> -->
            </a>
        </li>
    </ul>
</template>

<script>
export default {};
</script>

<style lang="scss">
.f-social-media-links {
    text-align: center;
    padding: 8px;

    .icon {
        @include links() {
            color: #3478f6;
        }
    }
    --f-social-media-links-color: #3478f6;

    li {
        display: inline-block;
        vertical-align: middle;

        &:not(:last-child) {
            padding-inline-end: 24px;
        }

        @include links() {
            transition: color $transition-length ease;
        }

        a:not(.btn):hover {
            color: #fff;
        }
    }

    .linkedin {
        position: relative;
        top: -3px;
    }
}
</style>
