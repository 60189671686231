<template>
    <div class="custom-dialog">
        <div class="dialog-form">
            <div class="stake-dialog">
                <div class="stake-title">
                    <div class="stake-text">Stake</div>
                    <FInput
                        placeholder="Stake amount"
                        v-model="amount"
                        class="inp-amount"
                        fieldSize="large"
                        validateOnInput
                        :validator="validator"
                        :errorMessage="errorMessage"
                        type="number"
                    >
                    </FInput>
                </div>
                <div v-if="!loading" class="btns">
                    <button class="btn secondary stake" @click="closeModal">
                        <span class=" button-text">
                            Close
                        </span>
                    </button>
                    <button
                        class="btn primary stake"
                        :disabled="isDisabled"
                        @click="action"
                    >
                        <span class="button-text">
                            Stake
                        </span>
                    </button>
                </div>
                <div v-if="loading" class="f-loading-more msg-content">
                    <pulse-loader color="#1969ff"></pulse-loader>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { mapGetters, mapState } from "vuex";
import { BigNumber } from "ethers";
import { toBigNumberFloat } from "@/utils/big-number.js";
import FInput from "../core/FInput/FInput.vue";

export default {
    components: { PulseLoader, FInput },
    props: {
        loading: { type: Boolean, default: false }
    },
    data() {
        return {
            amount: ""
        };
    },
    computed: {
        ...mapGetters(["symbol", "balance"]),
        ...mapState({
            address: state => state.address
        }),
        isDisabled() {
            return !this.amount || !this.address || Boolean(this.errorMessage);
        },
        errorMessage() {
            if (!this.amount) return "";
            else if (isNaN(Number(this.amount)))
                return "Amount must be a number";
            if (!isNaN(Number(this.amount))) {
                const amount = BigNumber.from(
                    toBigNumberFloat(this.amount.trim())
                );
                if (amount.lte(0)) return "Amount must be more than zero";
                else if (amount.gt(this.balance))
                    return "Stake amount exceeds balance";
                else return "";
            } else return "";
        }
    },
    methods: {
        closeModal() {
            this.$emit("close");
        },
        action() {
            this.$emit("action", this.amount);
        },
        validator(value) {
            if (isNaN(Number(value))) return false;
            else {
                if (!value) return true;
                else {
                    const amount = BigNumber.from(
                        toBigNumberFloat(value.trim())
                    );
                    return amount.lte(0) || amount.gt(this.balance)
                        ? false
                        : true;
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
.stake-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 180px;
}

.stake-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    width: 100%;
}

.stake-text {
    --title-color-dialog: #000;
    font-weight: 600;
    font-size: 26px;
    color: var(--title-color-dialog);
}

.small-text {
    font-weight: 400;
    font-size: 14px;
    color: gray;
}

.custom-dialog {
    position: fixed;
    top: -50px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
}

.dialog-form {
    --f-bg-dialog: #{#fff};
    border-radius: 8px;
    background-color: var(--f-bg-dialog);
    position: fixed;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 16px;
    width: 335px;
    @media (min-width: 640px) {
        border-radius: 18px;
        padding: 24px;
        width: 488px;
    }
    @media (min-width: 1020px) {
        border-radius: 24px;
        padding: 50px;
        width: 790px;
    }
}

.inp-amount {
    width: 100%;
    margin-top: 8px;
    div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
    }
    input {
        width: 100%;
    }
}
.close {
    outline: none;
    border: none;
    background-color: transparent;
    color: gray;
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 20px;
    cursor: pointer;
}

.btns {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 12px;
    gap: 8px;
    @media (min-width: 640px) {
        margin-top: 48px;
        flex-direction: row;
        gap: 18px;
    }
    .stake {
        max-width: 364px;
        width: 100%;
        height: 48px;
    }
}

.button-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
}
.msg-content {
    margin-top: 58px;
}
</style>
