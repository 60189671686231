var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"validator-list-dt"},[(!_vm.dValidatorListError)?[_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.dColumns,"items":_vm.dItems,"mobile-view":_vm.cMobileView,"loading":_vm.cLoading,"code":_vm.code,"first-m-v-column-width":"6","fixed-header":""},scopedSlots:_vm._u([{key:"column-logo",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('div',{staticClass:"validator-img"},[(value)?_c('img',{staticClass:"not-fluid",attrs:{"src":value,"alt":item.stakerInfo.name}}):_c('img',{staticClass:"not-fluid",staticStyle:{"width":"32px"},attrs:{"src":"/img/vinuchain-icon.svg","alt":"vinu logo"}})])])]):[_c('div',{staticClass:"validator-img"},[(value)?_c('img',{staticClass:"not-fluid",attrs:{"src":value,"alt":item.stakerInfo.name}}):_c('img',{staticClass:"not-fluid",staticStyle:{"width":"32px"},attrs:{"src":"/img/vinuchain-icon.svg","alt":"vinu logo"}})])]]}},{key:"column-stakerAddress",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('router-link',{attrs:{"to":{
                                    name: 'validator-detail',
                                    params: { address: value },
                                },"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])],1)]):[_c('router-link',{attrs:{"to":{
                                name: 'validator-detail',
                                params: { address: value },
                            },"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])]]}}],null,false,2239961843)})]:[_c('div',{staticClass:"query-error"},[_vm._v(_vm._s(_vm.dValidatorListError))])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }