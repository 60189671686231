<template>
    <div>
        <div
            v-click-outside="hide"
            ref="selector"
            class="switch-chain"
            tabindex="0"
        >
            <button
                class="switch-button"
                @click="active = !active"
                :class="{ 'button-active': active }"
            >
                <img src="/switch-chain.svg" class="not-fluid image-switch" />
            </button>
            <div v-if="active" class="options">
                <div
                    v-for="(option, idx) of labels"
                    :key="`${option.name}-${idx}`"
                    class=""
                    tabindex="0"
                    @click="onSelect(option.name)"
                    @keyup.enter="onSelect(option.name)"
                >
                    <a :href="option.link" class="option">
                        <span :class="{ 'text-active': val === option.name }">{{
                            option.name
                        }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapGetters } from "vuex";

export default {
    name: "FSwitchChain",
    data() {
        return {
            val: "Mainnet",
            active: false,
            labels: [
                {
                    name: "Mainnet",
                    link: "#"
                },
                {
                    name: "Testnet",
                    link: "#"
                }
            ]
        };
    },
    methods: {
        onSelect(value) {
            this.val = value;
            console.log(value);
            this.active = false;
        },
        hide() {
            this.active = false;
        }
    },
    computed: {
        ...mapGetters(["isTestMode", "explorerTestnet", "explorerMainnet"])
    },
    watch: {
        isTestMode(_isTestMode) {
            if (_isTestMode) this.val = "Testnet";
            else this.val = "Mainnet";
        }
    },
    mounted() {
        if (this.isTestMode) this.val = "Testnet";
        else this.val = "Mainnet";
        this.labels[0].link = this.explorerMainnet;
        this.labels[1].link = this.explorerTestnet;
    },
    directives: {
        ClickOutside
    }
};
</script>

<style lang="scss">
.switch-chain {
    display: flex;
    flex-direction: column;
    justify-items: center;
    position: relative;
    .image-switch {
        width: 28px;
        height: 28px;
    }
    .switch-button {
        --bg-button-active: #1969ff;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        border-radius: 24px;
    }
    .button-active {
        --bg-button-active: #1969ff;
        box-shadow: 0px 0px 2px var(--bg-button-active);
    }
}

.options {
    --bg-option-select: #fcfcfc;
    width: 109px;
    max-height: 240px;
    @media (min-width: 768px) {
        max-height: 361px;
    }
    position: absolute;
    background: var(--bg-option-select);
    border-radius: 6px;
    top: 35px;
    right: -24px;
    z-index: 5;
    a {
        text-decoration: none !important;
    }
}
.option {
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    width: 100%;
    font-size: 13px;
    cursor: pointer;
    transition: 150ms;
    span {
        --option-select-color: #747474;
        --option-select-color-active: #2b3954;
        color: var(--option-select-color);
    }
    .text-active {
        --bg-button-active: #1969ff;
        color: var(--option-select-color-active);
        font-weight: 700;
    }
    &:hover {
        background: #ffffff07;
    }
}
</style>
