import { toBigNumberFloat, formatStringFloat } from "@/utils/big-number.js";
import { Contract, providers, BigNumber } from "ethers";
import QuotaStaking from "../contracts/abi/QuotaStaking.abi.json";
import { safeWrite } from "../utils/safe";

const errMsg = "Warning! An error has occurred. Please try again.";
const QUOTA_STAKING_ADDRESS = process.env.VUE_APP_QUOTA_STAKING_ADDRESS;

export const state = {
    contract: null,
    addressTotalStake: 0,
    numberOfActiveWithdrawalRequest: 0,
    holdTime: 0,
    withdrawalLoading: false,
    withdrawalRequestIDs: [],
    withdrawalRequests: []
};

export const getters = {
    getUserTotalStake(state) {
        return state.addressTotalStake;
    },
    getWithdrawalLoading(state) {
        return state.withdrawalLoading;
    },
    getWithdrawalRequests(state) {
        return state.withdrawalRequests;
    },
    getHoldTime(state) {
        return state.holdTime;
    }
};

export const mutations = {
    setQuotaContract(state, contract) {
        state.contract = contract;
    },
    setQuotaAddressTotalStakeTotalStake(state, addressTotalStake) {
        state.addressTotalStake = addressTotalStake;
    },
    setNumberOfActiveWithdrawalRequestIDs(
        state,
        numberOfActiveWithdrawalRequest
    ) {
        state.numberOfActiveWithdrawalRequest = numberOfActiveWithdrawalRequest;
    },
    setQuotaHoldTime(state, holdTime) {
        state.holdTime = holdTime;
    },
    setQuotaStake(state, stake) {
        state.stakes.push(stake);
    },
    setQuotaStakes(state, stakes) {
        state.stakes = stakes;
    },
    setWithdrawalLoading(state, loading) {
        state.withdrawalLoading = loading;
    },
    setActiveWithdrawalRequestIDs(state, withdrawalRequestIDs) {
        state.withdrawalRequestIDs = withdrawalRequestIDs;
    },
    setActiveWithdrawalRequests(state, withdrawalRequest) {
        state.withdrawalRequestIDs = state.withdrawalRequests.push(
            withdrawalRequest
        );
    },
    setEmptyWithdrawalRequests(state) {
        state.withdrawalRequests = [];
    }
};

export const actions = {
    async createQuotaContract({ rootState, commit, dispatch }) {
        let provider = null;
        let signer = null;
        if (rootState.walletType === "metamask") {
            provider = new providers.Web3Provider(window.ethereum);
            signer = provider.getSigner(rootState.address);
        }
        if (rootState.walletType === "trustwallet") {
            provider = new providers.Web3Provider(window.ethereum);
            signer = provider.getSigner(rootState.address);
        }
        if (rootState.walletType === "wc") {
            console.log(rootState.walletconnect.wcProvider);

            provider = new providers.JsonRpcProvider(
                rootState.network.networkOptions.rpcUrls[0]
            );
            signer = provider.getSigner(rootState.address);
        }

        const contract = new Contract(
            QUOTA_STAKING_ADDRESS,
            QuotaStaking,
            signer
        );

        commit("setQuotaContract", contract);
        dispatch("getHoldTime");
        dispatch("getAddressTotalStake");
        dispatch("getActiveWithdrawalRequestIDs");
    },
    async getHoldTime({ state, commit }) {
        const holdTime = await state.contract.holdTime();
        commit("setQuotaHoldTime", holdTime.toNumber());
    },
    async getAddressTotalStake({ rootState, state, commit }) {
        try {
            const totalStake = await state.contract.getStake(rootState.address);
            commit(
                "setQuotaAddressTotalStakeTotalStake",
                formatStringFloat(totalStake)
            );
        } catch (err) {
            console.log("getAddressTotalStake err");

            return errMsg;
        }
    },
    async getNumberOfActiveWithdrawalRequestIDs({ rootState, state, commit }) {
        try {
            const numberOfActiveWithdrawalRequest = await state.contract.getNumberOfActiveWithdrawalRequestIDs(
                rootState.address
            );
            commit(
                "setNumberOfActiveWithdrawalRequestIDs",
                Number(numberOfActiveWithdrawalRequest)
            );
        } catch (err) {
            console.log("setNumberOfActiveWithdrawalRequestIDs err");

            return errMsg;
        }
    },
    async getActiveWithdrawalRequestIDs({
        rootState,
        state,
        commit,
        dispatch
    }) {
        if (!state.contract) return null;
        dispatch("getNumberOfActiveWithdrawalRequestIDs");
        commit("setWithdrawalLoading", true);
        try {
            const getActiveWithdrawalRequestIDs = await state.contract.getActiveWithdrawalRequestIDs(
                rootState.address,
                0,
                state.numberOfActiveWithdrawalRequest
            );

            commit(
                "setActiveWithdrawalRequestIDs",
                getActiveWithdrawalRequestIDs
            );

            const idsWR = await state.contract.getActiveWrRequests(
                rootState.address,
                0,
                state.numberOfActiveWithdrawalRequest
            );
            commit("setEmptyWithdrawalRequests");

            //     "name": "id",
            //     "type": "uint256"

            //     "name": "time",
            //     "type": "uint256"

            //     "name": "amount",
            //     "type": "uint256"

            //     "name": "unlockTime",
            //     "type": "uint256"

            //     "name": "completed",
            //     "type": "bool"

            idsWR.forEach((request, index) => {
                const id = request.id.toNumber();
                const time = request.time.toNumber();
                const amount = request.amount * 1;
                const unlockTime = request.unlockTime.toNumber();
                const completed = request.completed;

                if (time !== 0 && amount !== "0.0") {
                    const formattedTime = new Date(
                        time * 1000
                    ).toLocaleString();
                    console.log(
                        `WRReq #${index}: ID: ${id}, UnlockTime: ${unlockTime}, Time: ${formattedTime}, Amount: ${formatStringFloat(
                            amount
                        )} VC, Completed: ${completed}`
                    );
                    commit("setActiveWithdrawalRequests", {
                        index,
                        id,
                        time,
                        amount: formatStringFloat(amount),
                        unlockTime,
                        completed
                    });
                }
            });
            commit("setWithdrawalLoading", false);
        } catch (err) {
            console.log("setWithdrawalLoading err");
            console.log(err);
            commit("setWithdrawalLoading", false);
            return errMsg;
        }
    },

    // write functions
    async quotaStake({ state, dispatch }, payload) {
        dispatch("setDialogLoading", true);
        dispatch("setEmptyMsg");

        const amount = toBigNumberFloat(payload.stake);

        try {
            const [tx] = await safeWrite(
                state.contract.stake({
                    value: BigNumber.from(amount)
                })
            );

            console.log("tx", tx);
            dispatch("setSuccessMsg", `${tx.hash}`);
            dispatch("setDialogLoading", false);
            dispatch("getAddressTotalStake");

            return tx;
        } catch (err) {
            console.log(err);
            dispatch("setErrorMsg");
            dispatch("setDialogLoading", false);
            dispatch("getAddressTotalStake");
            return errMsg;
        }
    },
    async quotaUnstake({ state, dispatch }, payload) {
        dispatch("setDialogLoading", true);
        dispatch("setEmptyMsg");
        if (state.contract) {
            console.log(payload, "payload", toBigNumberFloat(payload.amount));

            const amount = toBigNumberFloat(payload.amount);

            try {
                console.log(amount, "quotaUnstake params");

                const [tx] = await safeWrite(state.contract.unstake(amount));

                console.log("quotaUnstake", tx);
                dispatch("setSuccessMsg", `${tx.hash}`);
                dispatch("setDialogLoading", false);
                dispatch("getAddressTotalStake");
                dispatch("getActiveWithdrawalRequestIDs");
            } catch (err) {
                console.log(err);
                dispatch("setErrorMsg");
                dispatch("setDialogLoading", false);
                dispatch("getAddressTotalStake");
                return errMsg;
            }
        }
    },
    async quotaWithdrawStake({ state, dispatch }, payload) {
        dispatch("setDialogLoading", true);
        dispatch("setEmptyMsg");
        if (state.contract) {
            try {
                console.log(payload.wrID, "withdraw params");
                // ethers
                const [tx] = await safeWrite(
                    state.contract.withdrawStake(payload.wrID)
                );

                console.log("withdraw", tx);
                dispatch("setSuccessMsg", `${tx.hash}`);
                dispatch("setDialogLoading", false);
                dispatch("getActiveWithdrawalRequestIDs");
            } catch (err) {
                console.log(err);
                dispatch("setErrorMsg");
                dispatch("setDialogLoading", false);
                return errMsg;
            }
        }
    }
};

export const quotaStaking = {
    state,
    getters,
    mutations,
    actions
};
