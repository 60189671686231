<template>
    <f-data-table
        fixed-header
        :columns="columns"
        :items="items"
        :loading="loading"
        force-loading
        class="f-data-table-body-bg-color"
        @action="unstake"
        @claim="claim"
    >
        <template v-slot:after-table>
            <div v-if="showFTMVault" class="tooltip-table">
                <f-info
                    show-on-hover
                    window-class="light"
                    window-style="max-width: 350px;"
                >
                    In this block, you can stake your VC in order to get rewards
                </f-info>
            </div>
            <div class="after-table">
                <div v-if="items.length" class="select-block">
                    <FSelect v-model="selectedValue" @select="onSelect" />
                    <div class="cashback">
                        <p>
                            Total cashback
                            <span class="gas"
                                >{{
                                    cashback
                                        ? formatNumberByLocale(cashback, 0)
                                        : 0
                                }}%</span
                            >
                        </p>
                        <div v-if="showFTMVault" class="tooltip-gas">
                            <f-info
                                show-on-hover
                                window-class="light"
                                window-style="max-width: 350px;"
                            >
                                You could commit
                                {{
                                    cashback
                                        ? formatNumberByLocale(
                                              Math.floor(cashback / 100),
                                              0
                                          )
                                        : 0
                                }}
                                transactions with 100% cashback for fee and 1
                                transaction with
                                {{
                                    cashback
                                        ? formatNumberByLocale(
                                              cashback % 100,
                                              0
                                          )
                                        : 0
                                }}% cashback each 24 hours
                            </f-info>
                        </div>
                    </div>
                </div>

                <div class="add-stake">
                    <router-link to="/delegate" class="btn-delegation">
                        <span>+Add Stake</span>
                    </router-link>
                </div>
            </div>
        </template>
    </f-data-table>
</template>

<script>
import FDataTable from "@/components/core/FDataTable/FDataTable.vue";
import appConfig from "../../app.config.js";
import FInfo from "@/components/core/FInfo/FInfo.vue";
import FSelect from "@/components/core/FSelect/FSelect.vue";
import { formatNumberByLocale } from "@/filters.js";

export default {
    components: {
        FDataTable,
        FInfo,
        FSelect
    },
    props: {
        columns: { type: Array },
        items: { type: Array },
        loading: { type: Boolean }
    },
    data() {
        return {
            showFTMVault: appConfig.flags.ftmVault,
            selectedValue: "",
            minStakingAmount: 0,
            ut: 0
        };
    },
    methods: {
        unstake(item) {
            this.$emit("unstake", item);
        },
        claim(item) {
            this.$emit("claim", item);
        },
        onSelect(value) {
            this.selectedValue = value.name;
            this.minStakingAmount = value.minStakingAmount;
            this.ut = value.ut;
        },
        formatNumberByLocale
    },
    computed: {
        stake() {
            return this.items
                .reduce((acc, curr) => (acc += +curr.amount), 0)
                .toFixed(2);
        },
        cashback() {
            if (this.stake && this.minStakingAmount && this.ut) {
                return (
                    (Number(this.stake) / this.minStakingAmount) * this.ut * 100
                );
            }
            return 0;
        }
    }
};
</script>
<style lang="scss">
a.btn-delegation:active,
a.btn-delegation:hover,
a.btn-delegation {
    text-decoration: none;
}

.btn-delegation {
    --btn-delegation-color: black;
    --btn-delegation-border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 194px;
    height: 50px;
    background-color: transparent;
    border: var(--btn-delegation-border);
    transition: 200ms;
    font-size: 18px;
    font-weight: 700;
    border-radius: 100px;
    span {
        color: var(--btn-delegation-color);
    }
    &:active {
        background-color: #3478f6;
        border: 1px solid #3478f6;
        span {
            color: white;
        }
    }
    @media (min-width: 768px) {
        &:hover {
            background-color: #3478f6;
            border: 1px solid #3478f6;
            span {
                color: white;
            }
        }
    }
}
.gas {
    color: #3478f6;
    font-size: 20px;
    font-weight: bold;
}

.after-table {
    width: 100%;
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: 768px) {
        flex-direction: row;
    }
    .select-block {
        width: 100%;
        max-width: 298px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        @media (min-width: 768px) {
            max-width: 211px;
        }
    }
    .cashback {
        display: flex;
        align-items: center;
        gap: 12px;
    }
    .tooltip-gas {
        display: flex;
        align-items: flex-start;
        padding-bottom: 16px;
    }
    .add-stake {
        padding-bottom: 16px;
        @media (min-width: 768px) {
            margin-left: auto;
        }
    }
}

.tooltip-table {
    position: absolute;
    right: 65px;
    top: 15px;
    z-index: 9;
}
@media (max-width: 1000px) {
    .tooltip-table {
        position: absolute;
        right: 40px;
        top: 15px;
        z-index: 9;
    }
}

@media (max-width: 800px) {
    .tooltip-table {
        position: absolute;
        right: 20px;
        top: 15px;
        z-index: 9;
    }
}

@media (max-width: 650px) {
    .tooltip-table {
        position: absolute;
        right: 0px;
        top: 15px;
        z-index: 9;
    }
}
</style>
