<template>
    <!-- <div :class="darkMode ? 'homeblocklist-dark' : 'homeblocklist'">
        <transition enter-active-class="fadelong-enter-active">
            <f-data-table
                v-show="show"
                :columns="dColumns"
                :items="dItems"
                :disable-infinite-scroll="!dHasNext"
                :loading="cLoading"
                fixed-header
                f-card-off
                mobile-view
                height="300px"
                v-bind="{ ...$attrs, ...$props }"
                class="f-data-table-body-bg-color"
            >
                <template v-slot:column-block="{ value, column }">
                    <div
                        v-if="column"
                        class="row no-collapse no-vert-col-padding"
                    >
                        <div class="col-4 f-row-label">{{ column.label }}</div>
                        <div class="col">
                            <router-link
                                :to="{
                                    name: 'block-detail',
                                    params: { id: value },
                                }"
                                :title="value"
                                >{{ value }}</router-link
                            >
                        </div>
                    </div>
                    <template v-else>
                        <router-link
                            :to="{
                                name: 'block-detail',
                                params: { id: value },
                            }"
                            :title="value"
                            >{{ value }}</router-link
                        >
                    </template>
                </template>

                <template v-slot:column-age="{ value, column }">
                    <div
                        v-if="column"
                        class="row no-collapse no-vert-col-padding"
                    >
                        <div class="col-4 f-row-label">{{ column.label }}</div>
                        <div class="col">
                            <timeago
                                :datetime="timestampToDate(value)"
                                :auto-update="1"
                                :converter-options="{ includeSeconds: true }"
                            ></timeago>
                        </div>
                    </div>
                    <template v-else>
                        <timeago
                            :datetime="timestampToDate(value)"
                            :auto-update="5"
                            :converter-options="{ includeSeconds: true }"
                        ></timeago>
                    </template>
                </template>
            </f-data-table>
        </transition>
    </div> -->

    <div :class="darkMode ? 'homeblocklist-dark' : 'homeblocklist'">
        <transition enter-active-class="fadelong-enter-active">
            <f-data-table
                v-show="show"
                :columns="dColumns"
                :items="dItems"
                :disable-infinite-scroll="!dHasNext"
                :loading="cLoading"
                fixed-header
                f-card-off
                mobile-view
                v-bind="{...$attrs, ...$props}"
                class="f-data-table-body-bg-color"
            >
                <template v-slot:column-block="{ value, column, col }">
                    <div v-if="column" class="row no-collapse no-vert-col-padding">
                        <!-- <div class="col-4 f-row-label">{{ column.label }}</div> -->
                        <router-link
                            class="col-4 f-row-label"
                            :to="{
                                name: 'block-detail',
                                params: { id: value },
                            }"
                            :title="value"
                            >{{ column.label }} <icon data="@/assets/svg/angle-right.svg" color="#B6B6B6" width="3px" height="6px"></icon></router-link
                        >
                        <div class="col">
                            {{ value }}
                        </div>
                    </div>
                    <template v-else>
                        <div class="animlist_label">
                            {{ col.label }} &rsaquo;
                        </div>
                        <span>{{ value }}</span>
                    </template>
                </template>

                <template v-slot:column-age="{ value, column, col }">
                    <div v-if="column" class="row no-collapse no-vert-col-padding">
                        <div class="col-4 f-row-label">{{ column.label }}</div>
                        <div class="col">
                            <timeago :datetime="timestampToDate(value)" :auto-update="1" :converter-options="{includeSeconds: true}"></timeago>
                        </div>
                    </div>
                    <template v-else>
                        <div class="animlist_label">
                            {{ col.label }}
                        </div>
                        <span>
                            <timeago :datetime="timestampToDate(value)" :auto-update="5" :converter-options="{includeSeconds: true}"></timeago>
                        </span>
                    </template>
                </template>

                <template v-slot:column-transaction_count="{ value, column, col }">
                    <div v-if="column" class="row no-collapse no-vert-col-padding">
                        <div class="col-4 f-row-label">{{ column.label }}</div>
                        <div class="col"> {{ value }} </div>
                    </div>
                    <template v-else>
                        <div class="animlist_label">
                            {{ col.label }}
                        </div>
                        <span>{{ value }}</span>
                    </template>
                </template>
            </f-data-table>
        </transition>
    </div>
</template>

<script>
import FBlockList from "@/data-tables/FBlockList.vue";
import FDataTable from "@/components/core/FDataTable/FDataTable.vue";
import { WEIToFTM } from "@/utils/transactions.js";
import { formatHexToInt, timestampToDate } from "@/filters.js";
import gql from "graphql-tag";
import { cloneObject } from "@/utils";
import { mapState } from "vuex";
import { pollingMixin } from "@/mixins/polling.js";

export default {
    name: "HomeBlockList",

    mixins: [pollingMixin],

    components: { FDataTable },

    props: {
        ...FBlockList.props,
    },

    data() {
        // return {
        //     ...FBlockList.data.call(this),
        //     show: true,
        // };
        return {
            dItems: [],
            dHasNext: false,
            dColumns: [
                {
                    name: 'block',
                    label: this.$t('view_block_list.block'),
                    itemProp: 'block.number',
                    formatter: formatHexToInt,
                    width: '160px'
                },
                {
                    name: 'age',
                    label: this.$t('view_block_list.age'),
                    itemProp: 'block.timestamp'
                },
                {
                    name: 'transaction_count',
                    label: this.$t('view_block_list.transaction_count'),
                    itemProp: 'block.transactionCount',
                    width: '140px'
                }
            ],
            show: true,
        }
    },

    computed: {
        cLoading() {
            return this.dItems.length === 0;
        },
        ...mapState({
            darkMode: (state) => state.darkMode,
        }),
    },

    created() {
        this.updateItems();
    },

    mounted() {
        this._polling.start(
            "update-blocks",
            () => {
                this.updateItems(true);
            },
            4800
        );
    },

    methods: {
        async updateItems(_animate) {
            this.dItems = await this.fetchData();

            if (_animate) {
                this.show = false;

                this.$nextTick(() => {
                    this.show = true;
                });
            }
        },

        /**
         * @returns {Promise<Array>}
         */
        async fetchData() {
            const data = await this.$apollo.query({
                query: gql`
                    query BlockList($cursor: Cursor, $count: Int!) {
                        blocks(cursor: $cursor, count: $count) {
                            totalCount
                            pageInfo {
                                first
                                last
                                hasNext
                                hasPrevious
                            }
                            edges {
                                block {
                                    hash
                                    number
                                    timestamp
                                    transactionCount
                                    gasUsed
                                }
                                cursor
                            }
                        }
                    }
                `,
                variables: {
                    cursor: null,
                    count: this.itemsPerPage,
                },
                fetchPolicy: "network-only",
            });

            return cloneObject(
                data.data && data.data.blocks && data.data.blocks.edges
                    ? data.data.blocks.edges
                    : []
            );
        },

        WEIToFTM,
        timestampToDate,
    },
};
</script>
<style lang="scss">
// .homeblocklist {
//     ::-webkit-scrollbar {
//         width: 10px;
//     }

//     /* Изменить цвет ползунка */
//     ::-webkit-scrollbar-thumb {
//         background: #e6e6e6;
//         border-radius: 90px;
//         height: 132px;
//     }

//     /* Изменить цвет фона */
//     ::-webkit-scrollbar-track {
//         background: #c1c1c1;
//         border-radius: 90px;
//     }

//     ::-webkit-scrollbar-button:vertical:decrement:before {
//         content: "";
//         height: 0;
//         width: 0;
//         border-top: 6px solid #888;
//         border-left: 3px solid transparent;
//         border-right: 3px solid transparent;
//         display: block;
//         margin: auto;
//     }

//     ::-webkit-scrollbar-button:vertical:increment:before {
//         content: "";
//         height: 0;
//         width: 0;
//         border-bottom: 6px solid #888;
//         border-left: 3px solid transparent;
//         border-right: 3px solid transparent;
//         display: block;
//         margin: auto;
//     }
//     .f-row-label{
//         max-width: 100%;
//         color: var(--f-link-color);
//     }
// }
// .homeblocklist-dark {
//     ::-webkit-scrollbar {
//         width: 10px;
//     }

//     /* Изменить цвет ползунка */
//     ::-webkit-scrollbar-thumb {
//         background: #b6b6b6;
//         border-radius: 90px;
//         height: 132px;
//     }

//     /* Изменить цвет фона */
//     ::-webkit-scrollbar-track {
//         background: #363636;
//         border-radius: 90px;
//     }

//     ::-webkit-scrollbar-button:vertical:decrement:before {
//         content: "";
//         height: 0;
//         width: 0;
//         border-top: 6px solid #888;
//         border-left: 3px solid transparent;
//         border-right: 3px solid transparent;
//         display: block;
//         margin: auto;
//     }

//     ::-webkit-scrollbar-button:vertical:increment:before {
//         content: "";
//         height: 0;
//         width: 0;
//         border-bottom: 6px solid #888;
//         border-left: 3px solid transparent;
//         border-right: 3px solid transparent;
//         display: block;
//         margin: auto;
//     }
//     .f-row-label{
//         max-width: 100%;
//         color: var(--f-link-color);
//     }
// }

.f-row-label{
    max-width: 100%;
    color: var(--f-link-color);
}
</style>
